
.section-heading{
    margin: 0 ;
}

.performance-list-item{
    display: flex;
    justify-content: space-between;
}

.performance-list-item p{
    margin-top: 0;
    margin-bottom: 5px;
}


.automated-parent-div{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    border-bottom: grey 1px solid;
}
